// src/translations/en_home.js
const en_home = {
  welcome: "Si estás aquí, tu empresa está lista para progresar",
  your_one_stop_solution: "Maximiza el potencial de tu empresa",
  your_one_stop_solution2: "2Maximiza el potencial de tu empresa2",
  your_one_stop_solution3: "2Maximiza el potencial de tu empresa2",
  your_one_stop_solution4: "2Maximiza el potencial de tu empresa2",
};

export default en_home;
