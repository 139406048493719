// src/translations/es_about.js
const es_about = {
  title:
    "Me presento, soy Xavier Medina, consultor en TI con años de experiencia",
  description: (
    <>
      Aprovecho las tecnologías más recientes para ofrecerte asesoría de alta
      calidad
      <br />
      Trabajaré contigo para analizar tus desafíos, proponiendo soluciones
      efectivas <br />y estratégicas que optimicen el potencial de tu empresa,
      mis palabras se <br />
      traducen en acciones concretas y resultados tangibles
    </>
  ),
};

export default es_about;
