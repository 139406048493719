// src/translations/en_home.js
const en_home = {
    welcome: "If you're here, your company is ready to progress",
    your_one_stop_solution: "Maximize your company's potential",
  };
  
  export default en_home;
  


  