// src/translations/es_platforms.js
const es_products = {
  title: "Tecnologias que integran mis servicios",
  description:
    "Plataformas líderes en el sector, aplicadas con precisión para resultados excepcionales",
  text: [
    "Windows",
    "Ubuntu",
    "Debian",
    "Rocky Linux",
    "Oracle Linux",
    "Zimbra",
    "Proxmox VE",
    "VMware",
    "Hyper-V",
    "Docker",
    "Kubernetes",
    "Amazon AWS",
    "Azure",
    "Fortinet",
    "Sophos",
    "WatchGuard",
    "Mikrotik",
    "Cisco",
    "Meraki",
    "PfSense",
    "OPNsense",
    "Ubiquiti",
    "HP",
    "Huawei",
    "TP-Link",
    "O365",
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "MySQL",
    "PostgreSQL",
    "WordPress",
    "GLPI",
    "Odoo",
    "Kali",
    "Nmap",
    "Burp Suite",
    "OpenVAS",
    "Snort",
    "Veeam",
    "Nakivo",
    "Issabela",
    "FreePBX",
    "Hikvision",
    "Dahua",
  ],
};

export default es_products;
