const en_contact = {
  title: "Would you like to have a coffee and discuss ideas?",
  description: "It would be a pleasure to discuss your business needs!",
  name: "Name",
  phone: "Phone",
  email: "Email",
  message: "Your message",
  send_message: "Send message",
  success_message: (
    <>
      Your message has been successfully sent!
      <br />
      I’ll be reviewing your ideas soon and will get in touch with you.
      <br />I hope you have a great day!
    </>
  ),
  clear_form: "Clear Form", // Texto del botón "Limpiar"
};

export default en_contact;
