// src/translations/en_navbar.js
const en_navbar = {
    home: "Home",
    about: "About",
    services: "Services",
    products: "Products",
    contact: "Contact",
  };
  
  export default en_navbar;
  