// src/translations/en_about.js
const en_about = {
  title:
    "I introduce myself, I am Xavier Medina, an IT consultant with years of experience",
  description: (
    <>
      I use the latest technologies to offer you high-quality consulting <br />I
      will work with you to understand your challenges and suggest effective{" "}
      <br />
      and strategic solutions that enhance your company's potential <br />
      My words turn into concrete actions and tangible results
    </>
  ),
};

export default en_about;
