// src/translations/es_navbar.js
const es_navbar = {
    home: "Inicio",
    about: "Acerca de",
    services: "Servicios",
    products: "Productos",
    contact: "Contacto",
  };
  
  export default es_navbar;
  