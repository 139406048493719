const es_contact = {
  title: "¿Te gustaría tomar un café y discutir ideas?",
  description: "¡Sería un placer hablar sobre tus necesidades de negocio!",
  name: "Nombre",
  phone: "Telefono",
  email: "Correo electrónico",
  message: "Tu mensaje",
  send_message: "Enviar mensaje",
  success_message: (
    <>
      ¡Tu mensaje ha sido enviado correctamente!
      <br />
      Pronto estaré revisando tus ideas y me pondré en contacto contigo.
      <br />
      ¡Espero que tengas un gran día!
    </>
  ),
  clear_form: "Limpiar Formulario", // Texto del botón "Limpiar"
};

export default es_contact;
